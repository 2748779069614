import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="section footer bg-dark sticky-bottom mt-auto">
      <Container>
        <Row className="align-items-center">
          <Col className="text-start">
            <Link to="/privacy-policy" className="text-muted">
              {t("Política de Privacidad")}
            </Link>
          </Col>
          <Col className="text-center">
            <p className="copy-rights text-muted mb-0">
              {t(
                "CONGRESO ESPACIAL CENTROAMERICANO 2025, TODOS LOS DERECHOS RESERVADOS"
              )}
            </p>
          </Col>
          <Col className="text-end">
            <a href="https://www.facebook.com/share/eWan6F5H5jQtH2k9/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer" className="text-muted me-3">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://x.com/cec_space?t=TxxSytGh4KGnj1MQ_o6ccQ&s=09" target="_blank" rel="noopener noreferrer" className="text-muted me-3">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://www.instagram.com/cec_space?igsh=NTVlZ3Y2YzBtMzM5" target="_blank" rel="noopener noreferrer" className="text-muted me-3">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/company/i-congreso-espacial-centroamericano/" target="_blank" rel="noopener noreferrer" className="text-muted me-3">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;