import { Col } from "reactstrap";
import { JustifiedText, VenueContainer, VenueRow } from "./Venue.stylesheets";
import { useTranslation } from "react-i18next";

export const Venuecontent = () => {
  const { t } = useTranslation();
  return (
    <VenueContainer className="vertical-content">
      <VenueRow className="align-items-center">
        <Col className="p-2 d-block d-lg-none">
          <img
            src="/assets/images/uvg.jpeg"
            className="img-thumbnail"
            alt="..."
          />
        </Col>
        <Col lg="6">
          <p className="fs-3">{t("LA SEDE DEL CONGRESO PARA EL 2025")} </p>
          <JustifiedText>{t("venueInfo")}</JustifiedText>
          <JustifiedText>
            {t(
              "Más información de la UDELAS en el siguientes enlace:"
            )}
          </JustifiedText>
          <a
            href="https://www.udelas.ac.pa/"
            className="btn btn-primary me-1 my-3"
            target="_blank"
            rel="noreferrer"
          >
            {t("UDELAS")}
          </a>
        </Col>
        <Col className="p-2 d-none d-lg-block">
          <img
            src="/assets/images/udelas.jpg"
            className="img-thumbnail"
            alt="..."
          />
          <p className="fw-light text-end">
            {t("Sede central de la UDELAS en Ciudad de Panamá, Panamá")}
            <br />
            {t("Créditos de la fotografía: UDELAS")}
          </p>
        </Col>
      </VenueRow>
    </VenueContainer>
  );
};
