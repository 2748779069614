import { useState } from "react";
import {
  Nav,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageButton from "./components/LanguageButton";
import { SLUGS } from "../../contstants/Contstants";

export const Navbar = ({ fixedTop = true }) => {
  const navItems = [
    {
      href: SLUGS.ABOUT,
      label: "Acerca del Congreso",
      options: [
        { href: SLUGS.ABOUT, label: "CEC 2025" },
        { href: SLUGS.PREVIOUS_EDITIONS, label: "Ediciones Anteriores" },
        //{ href: SLUGS.SCHOLARSHIPS, label: "Becas CEC 2025" },
        //{ href: SLUGS.HANDBOOK, label: "Handbook 2025" },
      ],
    },
    {
      href: SLUGS.VENUE,
      label: "Sede",
      options: [
        { href: SLUGS.VENUE, label: "UDELAS" },
        // { href: SLUGS.VENUE_CITY, label: "Ciudad de Guatemala" },
        // { href: SLUGS.HOTELS, label: "Alojamiento" },
      ],
    },
    {
      href: SLUGS.PROGRAM,
      label: "Programa",
      options: [
        { href: SLUGS.PROGRAM, label: "Programa CEC 2025" },
        { href: SLUGS.SPEAKERS, label: "Conferencistas" },
      ],
    },
    { href: SLUGS.CALLFORABSTRACTS, label: "Llamado a Ponencias", options: [] },
    { href: SLUGS.REGISTRATION, label: "Registro", options: [] },
  ];

  const { t } = useTranslation();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const toggle = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg navbar-custom sticky sticky-dark nav-sticky ${
          fixedTop ? "fixed-top" : ""
        }`}
        id="navbar"
      >
        <Container>
          <Link to="/" className="navbar-brand">
            <img
              src="/assets/images/navbar-logo.png"
              alt="Bootstrap"
              height="34"
            />
            <img
              src="/assets/images/cec-text.png"
              alt="Bootstrap"
              height="34"
              className="ms-2"
            />
          </Link>
          <NavbarToggler onClick={toggle}>
            <span className="ti-menu"></span>
          </NavbarToggler>
          <Collapse
            id="navbarCollapse"
            isOpen={isOpenMenu}
            className="navbar-collapse"
          >
            <Nav className="navbar-nav mx-auto" id="navbar-navlist">
              {navItems.map((item, key) =>
                item.options.length > 0 ? (
                  <UncontrolledDropdown nav inNavbar key={key}>
                    <DropdownToggle nav caret className="bg-dark">
                      {t(item.label)}
                    </DropdownToggle>
                    <DropdownMenu className="bg-dark">
                      {item.options.map((subOption, key) => (
                        <DropdownItem key={key} className="bg-dark">
                          <Link to={subOption.href}>{t(subOption.label)}</Link>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : (
                  <NavItem key={key}>
                    <NavLink href={item.href}>{t(item.label)}</NavLink>
                  </NavItem>
                )
              )}
            </Nav>
            <LanguageButton />
          </Collapse>
        </Container>
      </nav>
    </>
  );
};
