import { Col, Row, Container } from "reactstrap";
import {
  JustifiedText,
  PreviousEditionsContainer,
} from "./PreviousEditions.stylesheets";
import { useTranslation } from "react-i18next";

export const PreviousEditionsContent = () => {
  const { t } = useTranslation();

  return (
    <PreviousEditionsContainer className="vertical-content">
      <Container>
        {/* Bloque 1: CEC 2023 */}
        <Row className="align-items-center mb-5">
          <Col lg="6">
            <h3 className="fs-4 fw-bold">{t("CEC 2023")}</h3>
            <JustifiedText>{t("previousEditionsInfo2023")}</JustifiedText>
            <JustifiedText>
              {t("Más información del CEC 2023 en el siguiente enlace:")}
            </JustifiedText>
            <a
              href="https://cec-2023.sitrus.ai/"
              className="btn btn-primary mt-3"
              target="_blank"
              rel="noreferrer"
            >
              {t("CEC 2023")}
            </a>
          </Col>
          <Col lg="6">
            <img
              src="/assets/images/cec2023.png"
              className="img-thumbnail"
              alt="CEC SPACE 2023"
            />
            <p className="fw-light text-end mt-2">
              {t("Participantes del CEC 2023 afuera del Aula Magna de la UCR")}
              <br />
              {t("Créditos de la fotografía: Andrés Mora")}
            </p>
          </Col>
        </Row>

        {/* Bloque 2: CEC 2024 */}
        <Row className="align-items-center mb-5">
          <Col lg="6">
            <h3 className="fs-4 fw-bold">{t("CEC 2024")}</h3>
            <JustifiedText>{t("previousEditionsInfo2024")}</JustifiedText>
            <JustifiedText>
              {t("Más información del CEC 2024 en el siguiente enlace:")}
            </JustifiedText>
            <a
              href="https://cec-2024.sitrus.ai/"
              className="btn btn-primary mt-3"
              target="_blank"
              rel="noreferrer"
            >
              {t("CEC 2024")}
            </a>
          </Col>
          <Col lg="6">
            <img
              src="/assets/images/cec2024.jpg"
              className="img-thumbnail"
              alt="CEC SPACE 2024"
            />
            <p className="fw-light text-end mt-2">
              {t("Participantes del CEC 2024 en la Plaza del CIT de la UVG")}
              <br />
              {t("Créditos de la fotografía: UVG")}
            </p>
          </Col>
        </Row>
      </Container>
    </PreviousEditionsContainer>
  );
};
