import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import './CountdownTimer.css';

const CountdownTimer = () => {
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState({});
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [introMessage, setIntroMessage] = useState("");

  const targetDate = useMemo(() => new Date("2025-09-08T08:30:00"), []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const difference = targetDate.getTime() - now.getTime();

      if (difference <= 0) {
        setTimeLeft({
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00"
        });
        setIsTimeUp(true);
        clearInterval(interval);
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        setTimeLeft({
          days: String(days).padStart(2, '0'),
          hours: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, '0'),
          minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(2, '0'),
          seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, '0')
        });

        // Actualiza el mensaje dinámicamente
        if (days > 100) {
          setIntroMessage(t("longTimeMessage", "¡Prepárate para el gran día!"));
        } else if (days <= 100 && days > 30) {
          setIntroMessage(t("approachingMessage", "¡El congreso se acerca!"));
        } else {
          setIntroMessage(t("shortTimeMessage", "¡Falta poco para el gran día!"));
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate, t]);

  return (
    <div className="countdown-container">
      {isTimeUp ? (
        <div className="countdown-intro">
          <p>{t("finalMessage", "¡Nos vemos el 8 de septiembre de 2025!")}</p>
        </div>
      ) : (
        <>
          <div className="countdown-intro">
            <p>{introMessage}</p>
          </div>
          <div className="countdown" aria-live="polite" role="timer">
            {['days', 'hours', 'minutes', 'seconds'].map((unit) => (
              <div className="time-unit" key={unit}>
                <span className="number">{timeLeft[unit]}</span>
                <span className="label">{t(unit)}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default CountdownTimer;