import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "reactstrap";
import './styles.css';

export const Sponsors = () => {
  const { t } = useTranslation();
  const organizers = [
    {
      img: "/assets/images/organizers/udelas.png",
      alt: "Universidad Especializada de las Américas",
      href: "https://www.udelas.ac.pa/",
      maxHeight: "150px",
    },
    {
      img: "/assets/images/organizers/uvg.png",
      alt: "Universidad del Valle de Guatemala",
      href: "https://www.uvg.edu.gt/",
      maxHeight: "120px",
    },
    {
      img: "/assets/images/organizers/ucr.png",
      alt: "Universidad de Costa Rica",
      href: "https://www.ucr.ac.cr/",
      maxHeight: "120px",
    },
    {
      img: "/assets/images/organizers/unah.png",
      alt: "Facultad de Ciencias Espaciales UNAH",
      href: "https://cienciasespaciales.unah.edu.hn/",
      maxHeight: "120px",
    },
    {
      img: "/assets/images/organizers/verne.png",
      alt: "Verne Technologies Guatemala",
      href: "https://www.linkedin.com/company/verne-technologies-guatemala/",
      maxHeight: "150px",
    },
    {
      img: "/assets/images/organizers/jaguar.png",
      alt: "Jaguar Space, LLC",
      href: "https://cecspace.org/",
      maxHeight: "150px",
    },
    {
      img: "/assets/images/organizers/lauvg.png",
      alt: "Laboratorio Aeroespacial UVG",
      href: "https://www.uvg.edu.gt/",
      maxHeight: "150px",
    },
  ];
  
  /*
  const agencies = [
    {
      img: "/assets/images/participants/NASA.png",
      alt: "National Aeronautics and Space Administration",
      href: "https://www.nasa.gov/",
      maxHeight: "150px",
    },
    {
      img: "/assets/images/participants/DLR.png",
      alt: "German Aerospace Center",
      href: "https://www.dlr.de/en",
      maxHeight: "150px",
    },
    {
      img: "/assets/images/participants/JAXA.png",
      alt: "Japan Aerospace Exploration Agency",
      href: "https://global.jaxa.jp/",
      maxHeight: "150px",
    },
    {
      img: "/assets/images/participants/CONAE.png",
      alt: "Comisión Nacional de Actividades Espaciales",
      href: "https://www.argentina.gob.ar/ciencia/conae",
      maxHeight: "150px",
    },
    {
      img: "/assets/images/participants/AEM.png",
      alt: "Agencia Espacial Mexicana",
      href: "https://www.gob.mx/aem/",
      maxHeight: "120px",
    },
    {
      img: "/assets/images/participants/Portugal_Space_Agency.png",
      alt: "Portuguese Space Agency",
      href: "https://ptspace.pt/",
      maxHeight: "100px",
    },
    {
      img: "/assets/images/participants/EGSA.png",
      alt: "Egyptian Space Agency",
      href: "https://egsa-space-technology-portal.com/",
      maxHeight: "175px",
    },
    {
      img: "/assets/images/participants/ALCE.jpg",
      alt: "Agencia Agencia Latinoamericana y Caribeña del Espacio",
      href: "https://x.com/agenciaalce",
      maxHeight: "150px",
    },
    {
      img: "/assets/images/participants/AEC.png",
      alt: "Agencia Espacial Costarricence",
      href: "https://egsa-space-technology-portal.com/",
      maxHeight: "175px",
    }
  ];

  const sponsors = {
    constelacion: [
      {
        img: "/assets/images/sponsors/iias.png",
        alt: "International Institute for Astronautical Sciences",
        href: "https://astronauticsinstitute.org/",
        maxHeight: "125px",
      },
    ],
    galaxia: [
      {
        img: "/assets/images/sponsors/aerobots.png",
        alt: "Aerobots",
        href: "https://www.aerobots.gt/",
        maxHeight: "125px",
      },
    ],
    sistemaSolar: [
      {
        img: "/assets/images/sponsors/axient.png",
        alt: "Axient LLC Corp",
        href: "https://axientcorp.com/",
        maxHeight: "75px",
      },
      {
        img: "/assets/images/sponsors/seguros_universales.png",
        alt: "Seguros Universales",
        href: "https://www.universales.com/",
        maxHeight: "120px",
      },
      {
        img: "/assets/images/sponsors/reorbit.jpeg",
        alt: "ReOrbit",
        href: "https://www.reorbit.space/",
        maxHeight: "180px",
      },
      {
        img: "/assets/images/sponsors/optimen.png",
        alt: "Optimen",
        href: "https://www.optimen.com.mx/",
        maxHeight: "180px",
      },
    ],
    estrella: [
      {
        img: "/assets/images/sponsors/planet.png",
        alt: "Planet Labs",
        href: "https://www.planet.com/",
        maxHeight: "120px",
      },
      {
        img: "/assets/images/sponsors/heo_space.png",
        alt: "HEO",
        href: "https://www.heospace.com/",
        maxHeight: "100px",
      },
      {
        img: "/assets/images/sponsors/nassar.png",
        alt: "Nassar Abogados",
        href: "https://www.nassarabogados.com/",
        maxHeight: "200px",
      },
    ],
    planeta: [
      {
        img: "/assets/images/sponsors/rocket.png",
        alt: "Rocket Warehouse",
        href: "https://www.linkedin.com/company/rocket-warehouse-gt/",
        maxHeight: "75px",
      },
      {
        img: "/assets/images/sponsors/kapital.png",
        alt: "Cooperativa Kapital",
        href: "https://www.instagram.com/kapital.gt/",
        maxHeight: "120px",
      },
      {
        img: "/assets/images/sponsors/tettsa.png",
        alt: "Tettsa",
        href: "https://tienda.tettsa.gt/",
        maxHeight: "100px",
      },
      {
        img: "/assets/images/sponsors/steam_group.png",
        alt: "Steam Group",
        href: "https://steamgroup.net/",
        maxHeight: "120px",
      },
      {
        img: "/assets/images/sponsors/astralintu.png",
        alt: "Astralintu Space Technologies",
        href: "https://www.astralintu.com/",
        maxHeight: "120px",
      },
      {
        img: "/assets/images/sponsors/telescopios_de_guatemala.png",
        alt: "Telescopios de Guatemala",
        href: "https://telescopiosdeguate.wixsite.com/telescopiosgt",
        maxHeight: "150px",
      },
      {
        img: "/assets/images/sponsors/agia.png",
        alt: "Agia Solutions",
        href: "https://agiasolutions.com/",
        maxHeight: "120px",
      },
      {
        img: "/assets/images/sponsors/CNIECR.png",
        alt: "Cluster Nacional De Industria Espacial Costa Rica",
        href: "https://www.xn--clusternacionalindustriaespacialmxico-7jd.com/ecosistema-clie",
        maxHeight: "120px",
      },
      {
        img: "/assets/images/sponsors/orbital.png",
        alt: "Orbital Space Technologies",
        href: "https://orbitalspace.tech/",
        maxHeight: "120px",
      },
      {
        img: "/assets/images/sponsors/voyager.png",
        alt: "Voyager Space",
        href: "https://voyagerspace.com/",
        maxHeight: "120px",
      },
    ],
  };

  const participants = [
    {
      img: "/assets/images/participants/EARSC.png",
      alt: "European Association of Remote Sensing Companies",
      href: "https://earsc.org/",
      maxHeight: "120px",
    },
    {
      img: "/assets/images/participants/OAG.png",
      alt: "Observatorio Astronómico Centroamérica",
      href: "https://www.oaguatemala.org/",
      maxHeight: "120px",
    },
    {
      img: "/assets/images/participants/swisscontact.png",
      alt: "Swisscontact",
      href: "https://www.swisscontact.org/es",
      maxHeight: "120px",
    },
    {
      img: "/assets/images/participants/ICARO.png",
      alt: "ÍCARO S.A.",
      href: "https://www.linkedin.com/company/icaro-inversiones/",
      maxHeight: "120px",
    },
  ];

  const mediaPartners = [
    {
      img: "/assets/images/media/spaceside.png",
      alt: "Spaceside",
      href: "https://spaceside.eu/",
      maxHeight: "120px",
    },
    {
      img: "/assets/images/media/sca.png",
      alt: "Space Communications Alliance",
      href: "https://spacecommsalliance.com/",
      maxHeight: "100px",
    },
    {
      img: "/assets/images/media/epistemas.png",
      alt: "EpiSTEMas Podcast",
      href: "https://epistemas.com/",
      maxHeight: "200px",
    },
    {
      img: "/assets/images/media/beyondsight.png",
      alt: "Beyond Sight",
      href: "https://www.instagram.com/beyondsightgt/",
      maxHeight: "125px",
    },
  ];

  */

  const isMobile = window.matchMedia("(max-width: 767px)").matches;

  return (
    <Container fluid className="py-5 bg-dark">
      <h1 className="text-center pb-5 text-white title">
        {t("El Congreso Espacial Centroamericano es organizado por:")}
      </h1>

      <Row className="align-items-center">
        {organizers.map((organizer, index) => (
          <Col key={index} xs="12" sm={isMobile ? 12 : 6} md={isMobile ? 6 : 3} className="sponsor-col">
            <a href={organizer.href} target="_blank" rel="noopener noreferrer">
              <img
                src={organizer.img}
                alt={organizer.alt}
                className="img-fluid sponsor-logo"
                style={{ maxHeight: organizer.maxHeight }}
              />
            </a>
          </Col>
        ))}
      </Row>
    </Container>
  ); 

  /*
  return (
    <Container fluid className="py-5 bg-dark">
      <h1 className="text-center pb-5 text-white title">
        {t("El Congreso Espacial Centroamericano es organizado por:")}
      </h1>

      <Row className="align-items-center">
        {organizers.map((organizer, index) => (
          <Col key={index} xs="12" sm={isMobile ? 12 : 6} md={isMobile ? 6 : 3} className="sponsor-col">
            <a href={organizer.href} target="_blank" rel="noopener noreferrer">
              <img
                src={organizer.img}
                alt={organizer.alt}
                className="img-fluid sponsor-logo"
                style={{ maxHeight: organizer.maxHeight }}
              />
            </a>
          </Col>
        ))}
      </Row>

      <h2 className="text-center py-5 text-white title">
        {t("AGENCIAS ESPACIALES QUE PARTICIPAN EN EL CONGRESO ESPACIAL CENTROAMERICANO:")}
      </h2>
      <Row className="align-items-center">
        {agencies.map((agency, index) => (
          <Col key={index} xs="12" sm={isMobile ? 12 : 6} md={isMobile ? 6 : 3} className="sponsor-col">
            <a href={agency.href} target="_blank" rel="noopener noreferrer">
              <img
                src={agency.img}
                alt={agency.alt}
                className="img-fluid sponsor-logo"
                style={{ maxHeight: agency.maxHeight }}
              />
            </a>
          </Col>
        ))}
      </Row>

      <h2 className="text-center py-5 text-white title">
        {t("El Congreso Espacial Centroamericano es patrocinado por:")}
      </h2>

      <div>
        <h3 className="text-center py-3 text-white">{t("PATROCINADORES CONSTELACIÓN")}</h3>
        <Row className="align-items-center">
          {sponsors.constelacion.map((sponsor, index) => (
            <Col key={index} xs="12" className="d-flex justify-content-center sponsor-col">
              <a href={sponsor.href} target="_blank" rel="noopener noreferrer">
                <img
                  src={sponsor.img}
                  alt={sponsor.alt}
                  className="img-fluid sponsor-logo"
                  style={{ maxHeight: sponsor.maxHeight }}
                />
              </a>
            </Col>
          ))}
        </Row>
      </div>

      <div>
        <h3 className="text-center py-3 text-white">{t("PATROCINADORES GALAXIA")}</h3>
        <Row className="align-items-center">
          {sponsors.galaxia.map((sponsor, index) => (
            <Col key={index} xs="12" className="d-flex justify-content-center sponsor-col">
              <a href={sponsor.href} target="_blank" rel="noopener noreferrer">
                <img
                  src={sponsor.img}
                  alt={sponsor.alt}
                  className="img-fluid sponsor-logo"
                  style={{ maxHeight: sponsor.maxHeight }}
                />
              </a>
            </Col>
          ))}
        </Row>
      </div>

      <div>
        <h3 className="text-center py-3 text-white">{t("PATROCINADORES SISTEMA SOLAR")}</h3>
        <Row className="align-items-center">
          {sponsors.sistemaSolar.map((sponsor, index) => (
            <Col key={index} xs="12" md={isMobile ? 6 : 4} className="sponsor-col">
              <a href={sponsor.href} target="_blank" rel="noopener noreferrer">
                <img
                  src={sponsor.img}
                  alt={sponsor.alt}
                  className="img-fluid sponsor-logo"
                  style={{ maxHeight: sponsor.maxHeight }}
                />
              </a>
            </Col>
          ))}
        </Row>
      </div>

      <div>
        <h3 className="text-center py-3 text-white">{t("PATROCINADORES ESTRELLA")}</h3>
        <Row className="align-items-center">
          {sponsors.estrella.map((sponsor, index) => (
            <Col key={index} xs="12" md={isMobile ? 6 : 4} className="sponsor-col">
              <a href={sponsor.href} target="_blank" rel="noopener noreferrer">
                <img
                  src={sponsor.img}
                  alt={sponsor.alt}
                  className="img-fluid sponsor-logo"
                  style={{ maxHeight: sponsor.maxHeight }}
                />
              </a>
            </Col>
          ))}
        </Row>
      </div>

      <h3 className="text-center py-3 text-white">{t("PATROCINADORES PLANETA")}</h3>
      <Row className="align-items-center">
        {sponsors.planeta.map((sponsor, index) => (
          <Col key={index} xs="12" md={isMobile ? 6 : 4} className="sponsor-col">
            <a href={sponsor.href} target="_blank" rel="noopener noreferrer">
              <img
                src={sponsor.img}
                alt={sponsor.alt}
                className="img-fluid sponsor-logo"
                style={{ maxHeight: sponsor.maxHeight }}
              />
            </a>
          </Col>
        ))}
      </Row>

      <h2 className="text-center py-5 text-white title">
        {t("PARTICIPAN EN EL CONGRESO ESPACIAL CENTROAMERICANO:")}
      </h2>
      <Row className="align-items-center">
        {participants.map((participant, index) => (
          <Col key={index} xs="12" md={isMobile ? 6 : 4} className="sponsor-col">
            <a href={participant.href} target="_blank" rel="noopener noreferrer">
              <img
                src={participant.img}
                alt={participant.alt}
                className="img-fluid sponsor-logo"
                style={{ maxHeight: participant.maxHeight }}
              />
            </a>
          </Col>
        ))}
      </Row>

      <h2 className="text-center py-5 text-white title">
        {t("MEDIOS DE COMUNICACIÓN CUBRIENDO EL CONGRESO ESPACIAL CENTROAMERICANO:")}
      </h2>
      <Row className="align-items-center">
        {mediaPartners.map((partner, index) => (
          <Col key={index} xs="12" sm={isMobile ? 12 : 6} md={isMobile ? 6 : 3} className="sponsor-col">
            <a href={partner.href} target="_blank" rel="noopener noreferrer">
              <img
                src={partner.img}
                alt={partner.alt}
                className="img-fluid sponsor-logo"
                style={{ maxHeight: partner.maxHeight }}
              />
            </a>
          </Col>
        ))}
      </Row>
    </Container>
  ); */
};